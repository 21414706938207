<template>
  <div class="hold-transition">
    <loading v-if="cargando" />
    <div class="wrapper">
      <div class="content-wrapper">
        <section class="content-header">
          <div class="container-fluid">
            <div class="row">
              <div class="col-sm-6">
                <h5 class="mb-0">Ofertas Adicionales</h5>
              </div>
              <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                  <li class="breadcrumb-item">
                    <router-link to="/">Home</router-link>
                  </li>
                  <li class="breadcrumb-item active">Hidrocarburos</li>
                  <li class="breadcrumb-item active">Ofertas Adicionales</li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <section class="content">
          <oferta-adicionales-trans
            v-if="$store.getters.getUser.empresas.length > 0"
          ></oferta-adicionales-trans>
          <oferta-adicionales-front v-else></oferta-adicionales-front>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
import OfertaAdicionalesTrans from "./OfertaAdicionalesTrans.vue";
import OfertaAdicionalesFront from "./OfertaAdicionalesFront.vue";
import Loading from "../../../../components/Loading";
export default {
  name: "OfertaAdicionalIndex",
  components: {
    OfertaAdicionalesTrans,
    OfertaAdicionalesFront,
    Loading,
  },
  data() {
    return {
      cargando: false,
    };
  },
};
</script>
